<template>
      <el-row class="item">
          <ul class="nav">
            <li v-for="(item,index) in obj.content" :key="index" :type="item.type" :class="obj.currentIndex === index?'active':''" @click="changeIndex(index)">{{item.name}}</li>
          </ul>
      </el-row>
</template>

<script>
  export default {
    name:"Nav",
    data(){
        return{
           
        }
    },
    props:{
        obj:{
            type:Object
        }
    },
    methods:{
      changeIndex(index){
        this.obj.currentIndex = index;
        this.$emit("clickIndex",this.obj.currentIndex)
      }
    },
   created(){
    
     
    }
  }
</script>

<style lang="less" scoped>
@import "~style/theme.less";
  .nav {
    li {
        float: left;
        padding-right:15px;
        font-size: 14px;
        cursor: pointer;
    }
    .active {
        color: @theme;
        font-weight: bold;
      }
  }
  

</style>
